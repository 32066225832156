:root{
  --color-main: #42BABF;
  --color-darkermain: #31898C;
  --color-turquoise: #66C1BF;
  --color-aquamarine: #95CFBD;
  --color-passwordgreen: #3DCC80;
  --color-selectcontainer: #40D6B1;
  --color-deepblue: #40AAD6;
  --color-darkred: #BB1817;
  --color-orangebrown: #b36e6e;
  --color-lightred: #C70D6C ;
  --color-darkpurple: #9966C1;
  --color-purple: #3D7CCC;
  --color-lightorange: #FFD8C1;
  --color-darkorange: #FF9153;
  --color-blackoutline: #1D1D1D;
  --color-lightgrey: #6F7576;
  --color-lightergrey: #C4C4C4;
  --color-o-grey: #dbcece;
  --color-grey: #A0B0B0;
  --color-white: #FFFFFF;
}

*{
  font-family: 'Roboto', sans-serif;
}

#SignUp, #me {
  input {
    font-style: italic;
  }

  .form-input {
    padding: 8px 8px 8px 18px;
  }

  .form-switch {
    .form-check-input {
      border-color: #CAE1DD !important;
      background-color: #D9D9D9 !important;
    }
  }
  .age-label {
    //padding-left: -10px;
    margin-left: 8px;
  }

  .age-input {
    padding-left: 21px;
  }

  .activities-container {
    margin-top: 15px;
    font-style: italic;
  }

  @media screen and (max-width: 700px) {
    label:not(.sex-label) {
      margin-left: 10px;
    }

    .activities-container {
      margin-left: 0.95rem;
      label {
        margin-left: 0;
      }
    }

    .age-group {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: 25px;
    }

    .label-check {
      label {
        margin-left: 0px;
      }
    }
  }

  @media screen and (min-width: 701px) {
    .name-group {
      width: 50% !important;
    }

    .email-container {
      width: 60% !important;
    }
  }
}

.form-input {
  border-radius: 15px;
  height: 40px;
  //color: var(--color-white);
  border: 1.5px solid #DCDCDC;
  padding: 8px 13px 8px 21px;

  &:focus, &:focus-visible  {
    border: 1.5px solid var(--color-deepblue);
    outline: none;
  }

  &-textarea{
    width: 100%;
    padding: 0.4em;
    min-height: 5em;
    resize: none;
  }
}

.spinner-border {
  color: var(--color-turquoise);
}

.iOS {
  input[type="password"] {
    &:not(:placeholder-shown) {
      font-family: Verdana;
      letter-spacing: 0.105em;
      font-size: 9px;
    }
  }
}

.dropdown-item {
  font-style: italic !important;
}

.global-title {
  text-transform: uppercase;
  font-size: 2em;
  font-weight: bold;
  font-style: italic;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media screen and (min-width: 701px) and (max-width: 1000) {
    max-width: 40%;
  }

  &-internal{
    color: var(--color-darkermain);
    margin: auto 0;
    width: 100%;
    text-align: left;
    line-height: 1.5em;
    font-size: 1.5em;
  }
}

.global-subtitle {
  margin: 0.6em 0 0 0;
  max-width: 22em;
  font-size: 1.1em;
  font-weight: 400;
}

.white-title {
  color: var(--color-white);
}

.lightgrey-title{
  color: var(--color-lightergrey);
  font-style: italic;
}

.global-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
}

.global-ellipsis-cards{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.home-slider-wrapper{
  justify-content: center;
  align-content: center;
  text-align: center;
  margin: 0 auto;
  max-width: 80%;
}

.global-card-message{
  background: linear-gradient(97.22deg, rgb(121,224,202) 0%, rgb(121,197,225) 96%);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  padding: 0.75em 3.5em;
  width: 100%;
  max-width: 24em;
  margin: 0 auto;
  transition: 0.4s;
  font-size: 0.8rem;
  border-radius: 20px;
  box-shadow: -6px 6px 30px rgba(0, 0, 0, 0.15);

  .global-card-message-title{
    color: var(--color-purple);
    margin: 15px 0 0.25rem 0;
    font-size: 1.25em;
    font-weight: bold;
    font-style: italic;

    img {
      height: 16px;
      margin-right: 2px;
      margin-top: -3px;
    }
  }

  .global-card-message-paragraph{
    color: var(--color-white);
    font-style: italic;
  }
}

.divider-or{
  display: flex;
  width: 100%;
  max-width: 21rem;
  color: var(--color-darkermain);

  hr{
    width: 100%;
    height: 0.2vh;
    color: var(--color-darkermain);
  }

  h5{
    margin: auto 0.6rem;
  }
}

hr.newhr{
  margin: auto 2em;
  border: 1px solid var(--color-white);
}

.all-card{
  position: relative;
  height: 21em;
  max-width: 46em;
  border-radius: 10px;
  padding: 2em;
  cursor: pointer;

  .card-title {
    font-size: 1.9em;
  }

  .card-subtitle {
    font-size: 1em;
  }

  .card-counter{
    position: absolute;
    left: 10%;
    bottom: 6%;
    padding: 0.6em;
    min-width: 3em;
    text-align: center;
    border-radius: 15px;
    background-color: var(--color-turquoise);
    h6{
      margin: 0.2em !important;
    }
  }

  &:first-of-type{
    .card-counter{
      background-color: var(--color-purple) !important;
    }
  }

}

.agreements {
  .form-check {
    display: flex;
    align-items: center;

    input[type=checkbox] {
      margin-right: 5px;
    }

    label {
      font-style: italic;
      color: #6F7576;
    }
  }

  .form-check-input[type=checkbox] {
    width: 17px;
    height: 17px;
    background: white !important;
  }

  .form-check-input:checked[type=checkbox] {
    background: url('../public/images/icons/check.png') no-repeat left center !important;
    border-color: #D9D9D9;
    border-width: 1px;
  }
}

form.user-data-form {
  display: flex;
  flex-direction: column;
  margin: 0 5%;
  max-width: 700px;
  gap: 0.6em;

  .form-wrapper{
    width: 100%;
    max-width: 600px;
  }

  .name-container-left, .password-container{
    display: flex;
    flex-direction: row;
    gap: 1em;
    //justify-content: center;
    align-items: center;

    .name-group {
      width: 100%;
    }

    .name-container-right {
      display: flex;
      flex-direction: row;
      gap: 1em;
      align-items: center;

      .age-input{
        max-width: 4em;

        &::placeholder {
          opacity: 0.5;
        }
      }
    }

    .form-check {
      display: flex;
      justify-content: center;
      align-self: center;
      align-items: center;
      //transform: translateY(28%);

      label {
        margin-left: -2em;
      }

      .form-check-input {
        height: 34px;
        width: 64px;
        //background-position-x: 30px !important;
        margin-left: 0.5em;
        transition: 0.4s ease;
        cursor: pointer;
      }
    }

    .password-right-container{
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1em;
      width: 100%;

      .field-group{
        width: 100%;
      }
    }
    @media screen and (max-width: 700px){
      align-items: flex-start;
      flex-direction: column;

      .password-right-container{
        align-items: end;
      }
    }
  }

  @media screen and (max-width: 800px){
    .name-container, .password-container{
      flex-direction: column;
      align-items: normal;

      .name-container-left, .name-container-right, .name-group {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: 800px) {
    max-width: 100%;
  }
}

.separator-panel {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
}

.activities-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 701px){
    margin-left: 1rem;
  }

  label {
    font-size: 12px;
    margin-bottom: 10px;
  }

  .activities-panel {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    gap: 0.3em;
  }

  .dropdown-toggle::after {
    display: none;
  }

  .chip-tag {
    color: var(--color-white);
    font-size: 12px;
    font-style: italic;
    padding: 4px 23px 4px 8px;

    .x-icon {
      margin-top: -1px;
      position: absolute;
      cursor: pointer;
    }
  }

  .plus-icon {
    font-size: larger;
    height: 100%;
    width: 100%;
  }

  .tag-plus {
    height: 20px;
    width: 20px;
  }

  .add-toggle {
    height: 25px;
    width: 25px;
    padding-left: 0;
    padding-top: 0;
    background: transparent;
    border: none;
    &.btn:first-child:hover, :not(.btn-check)+.btn:hover {
      background: transparent;
      background-color: transparent;
      border: none;
    }

    img {
      //width: 25px;
      height: 25px;
    }

    i {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

details[open] summary ~ * {
  animation: sweep .5s ease-in-out;
}

@keyframes sweep {
  0%    {opacity: 0; transform: translateX(-20px)}
  20%    {opacity: 0.2; transform: translateX(-15px)}
  40%    {opacity: 0.4; transform: translateX(-10px)}
  60%    {opacity: 0.6; transform: translateX(-5px)}
  80%    {opacity: 0.8; transform: translateX(0)}
  100%  {opacity: 1; transform: translateX(0)}
}

.main-content-container{
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;

  .main-content-wrapper {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 3em;

    .main-content{
      -webkit-flex: 1 1 auto;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      -webkit-order: 1;
      -ms-flex-order: 1;
      order: 1;
      min-height: 70vh;
      min-width: 0;

      &:not(.mobile) {
        margin-left: 200px;
      }

      &:only-child {
        margin-left: 0;
      }
    }

    &.mobile-content-wrapper {
      margin-top: 0;
    }

    &.webview {
      margin-top: -50px;
    }
  }

  @media screen and (max-width: 700px) {
    .main-content{
      margin-left: 0 !important;
      //min-width: 0;
    }
  }
}

.toast-pos {
  padding-top: 62px;
  padding-right: 12px;
  font-style: italic;
}

.carousel {
  border-radius: 25px;
}

.range-input {
  accent-color: #2d995b;
  opacity: .75;
}

.form-check-input:checked {
    background-color: #0d6efd;
    border-color: red;
}

.playlist-modal {
  max-width: 700px !important;

  .react-loading-skeleton {
    width: calc(100% - 30px) !important;
  }

  .playlist-grid-wrapper {
    margin-top: 8px;
  }
  .no-exercises {
    margin-top: 2px !important;
  }
  .modal-content {
    border-radius: 30px;
  }

  .global-title {
    margin-left: 16px;
    margin-top: 4px;
  }

  &:not(.mobile) {
    .modal-content {
      margin-left: 50px;
    }
  }
}

@media screen and (max-width: 700px) {
  .modal-dialog {
    margin: 0 !important;
  }
}

.reauth-modal {
  max-width: 600px !important;
  .modal-content {
    @media screen and(min-width: 701px) {
       margin-left: 140px;
    }
  }

  .modal-title {
    font-size: 16px;
    font-weight: 400;
  }

  .pwd-reset {
    margin-top: 15px;
    margin-bottom: -2px;
  }

  .fields-panel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    input {
      margin-bottom: 15px;
      width: 360px;
      max-width: 90%;
      color: black;
      font-weight: 300;
    }
  }
}

input {
  &::placeholder {
    color: #C4C4C4;
    font-weight: bold;
    opacity: 1;
    font-style: italic;
  }
}

.field-group {
  display: flex;
  flex-direction: column;

  label {
    font-style: italic;
    margin-left: 19px;
    font-weight: 400;
    color: var(--color-blackoutline);
    font-size: 14px;
  }

  input {
    outline: none;
  }
}

.main-btn {
  background: var(--color-lightred) !important;
  border: none !important;
  color: white;
  border-radius: 30px !important;
  font-weight: bold !important;
  font-style: italic !important;
}

.continue-btn {
  margin-top: 0.3em;
  width: 14rem;
  height: 3rem;

  &.mobile-kickoff {
    margin-top: 92px;
  }
}

.negative-btn {
  background: var(--color-lightgrey) !important;
  border: none !important;
  border-radius: 30px !important;
  font-weight: bold !important;
  font-style: italic !important;
}

.begin-panel {
  width: 35%;
  margin-top: 45px;
  height: fit-content;

  .begin-btn {
    background: radial-gradient(50% 50% at 50% 50%, #C70D6C 0%, rgba(199, 13, 108, 0.41) 100%);
    width: 150px;
    height: 150px;
    border-radius: 50%;
    text-align: center;
    margin: 0 auto;
    padding-top: 59px;
    font-style: italic;
    font-weight: bold;
    font-size: 20px;
    color: white;
    text-transform: uppercase;
    cursor: pointer;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    @media screen and (max-width: 700px) {
      transform: scale(0.95);
    }

    &:hover{
      box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
    }
  }
}

.view-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
  color: white;
  z-index: 2;
  /* ... other styles ... */
}

.related-panel {
  padding: 20px 3% 20px 3%;
  margin-top: 50px;

  .summary-div{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin: 0 auto;
    max-width: calc(50vw + 6rem);

    .title-summary {
      color: var(--color-darkermain);
      font-size: 23px;
      font-weight: 700;
      text-transform: uppercase;
      font-style: italic;
    }
  } 
}

/* End Exercises */

/* Tags */
.chip-tag {
  border-radius: 20px;
  color: white;
  font-size: 13px;
  padding: 5px 12px;

  .x-icon {
    margin-top: -3px;
    cursor: pointer;
  }

}

.toast-container {
  width: 350px;

  .toast-header {
    padding: 11px 13px;
    img {
      display: none;
    }
  }
}

/* Modal */
.global-modal {
  .toast-container {
    .toast-body{
      text-align: left !important;
    }
  }
  .modal-header{
    background: linear-gradient(274.76deg, #66C1BF 14.14%, #9BD1C2 80.61%);
    color: var(--color-white);
    border-top-left-radius: 20px !important;
    border-top-right-radius: 20px !important;
    display: flex;
    justify-content: center;
    padding: 1rem 1rem;
  }

  .modal-title{
    width: 80%;
    line-height: 1.5;
    font-size: 1.5rem;
    font-weight: bold;
    font-style: italic;
  }

  .modal-content{
    z-index: 9999;
    border-radius: 20px !important;
    margin: 0 auto;
    width: 100%;
    max-width: 500px;
    text-align: center;

    input {
      border-radius: 15px;
      height: 40px;
      font-weight: normal;
      //color: var(--color-white);
      border: 1.5px solid #DCDCDC;
      padding: 8px 13px 8px 21px;
      font-style: italic;
      font-family: 'Roboto', sans-serif;
      color: #212529;

      &:focus, &:focus-visible  {
        border: 1.5px solid var(--color-deepblue);
        outline: none;
      }

      &-textarea{
        width: 100%;
        padding: 0.4em;
        min-height: 5em;
        resize: none;
      }
    }

    .modal-footer {
      border-top: none;
    }
  }

  .btns-panel{
    display: flex;
    justify-content: center;
    padding: 1em 0;
  }

  .no-btn {
    color: var(--color-lightergrey);
    background: white;
    width: 70px;
    border: 1px solid var(--color-lightergrey);
    margin-right: 35px;
    font-style: italic;
    border-radius: 20px;
  }

  .yes-btn {
    color: white;
    background: var(--color-deepblue);
    width: 70px;
    border: 1px solid var(--color-deepblue);
    font-style: italic;
    border-radius: 20px;
  }
}

.modal-dialog {
  max-width: 70% !important;
  margin: 0 auto !important;
}

@media screen and (max-width: 700px) {
  .modal-dialog {
    max-width: 95% !important;
  }
}

/* Custom Scrollbar */

/* width */
::-webkit-scrollbar {
  width: 0.5em;
  display: none;
  @media screen and(max-width: 768px){
    display: none;
  }
}

/* Track */
::-webkit-scrollbar-track {
  background: #ECF5F4;
  //-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
  border-radius: 15px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #CAE1DD;
  //-webkit-box-shadow: inset 0 0 6px     rgba(0,0,0,0.5); 
  border-radius: 15px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--color-main);
}

#program-summary, #perform-routine {
  @media screen and (max-width: 700px) {
    #exercise-playlist {
      max-width: 100% !important;
    }
    .container {
      padding: 18px 17px 18px 17px !important;
      max-width: 100% !important;
    }

    .global-card {
      margin-right: 18px !important;
      .global-card-content {
        padding: 0.25em 0.5em 2em 0.5em !important;
      }
    }

    .global-card, .card-image {
      height: 100% !important;
      max-width: 100% !important;
    }

    .begin-mobile {
      font-size: 15px;
      padding: 3px 18px;
      position: absolute;
      margin-left: auto;
      right: 10px;
      bottom: 5px;
    }

    .info-panel {
      .title {
        font-size: 19px !important;
        white-space: nowrap;
      }
    }


    .related-panel {
      padding: 0 !important;
    }

    .modal-content {
      max-height: 60vh !important;
    }

    .modal-dialog {
      margin: 0 !important;
      transform: translate(5px,-50px) !important;
    }
  }
}

#routine-summary.not-modal, #create-routine{
  @media screen and (max-width: 700px) {
    .container {
      justify-content: center;
      .begin-panel {
        margin-left: 1%;
      }
    }
  }
}

.acts-div {
  display: flex;
  //flex-direction: column;
  flex-wrap: wrap;
  gap: 4px;
}

.timetarget-panel {
  display: flex;
  flex-direction: column;
  margin-top: 8px;

  img {
    margin-right: 8px;
  }

  span {
    font-style: italic;
    font-size: 15px;
    font-weight: 700;
    margin-right: 0px;
    text-transform: capitalize;
  }

  .time {
    color: #40AAD6;
    margin-right: 20px;
    line-height: 1.75;
  }

  .muscle-g {
    color: #FF9153;
    line-height: 1.65;
  }

  .activities-panel {
    display: flex;
    margin-top: 9px;
    max-width: 75%;


    img {
      //margin-right: 2px;
      width: 25px;
      height: 25px;
    }

    .chip-tag:not(:first-child) {
      margin-left: 16px;
    }

    .act-label {
      color: #9966C1;
    }
  }
}

.activities-panel {
  @media (width <= 1024px) {
    max-width: 100% !important;
  }
}

.timetarget-panel.pos-abs {
  @media (width <= 1024px) {
    position: absolute;
    margin-top: 42px;
  }
}

.info-panel {
  .title {
    @media (width <= 480px) {
      line-height: 1.19;
    }
  }
}