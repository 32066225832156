//$loader-color: #3c4285;
$loader-color: #fff;

.loader-container {
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100vw;
  position: absolute;
  background-color: black;
  opacity: 0.55;
  //opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 40px; /* Reduced from 80px */
  height: 40px; /* Reduced from 80px */
  opacity: 1;
  z-index: 99999;

/*   @media (width > 500px) {
    width: 80px;
    height: 80px;
  } */
  
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 40px; /* Reduced from 64px */
  height: 40px; /* Reduced from 64px */
  margin: 5px; /* Reduced from 8px */
  border: 5px solid var(--color-turquoise); /* Reduced from 8px */
/*   @media (width > 500px) {
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid var(--color-turquoise);
  } */
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--color-turquoise) transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
