.sidebar {
  &.mobile {
    display: none !important;
  }

  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  background-color: var(--color-darkermain);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
  height: 100%;
  min-height: 100vh;
  position: fixed;

  & > * {
    list-style: none !important;
    text-decoration: none !important;
  }

  .frog-logo {
    margin: 28px 0;
  }

  .nav > * {
    list-style: none !important;
    text-decoration: none !important;
  }

  .underline {
    //opacity: 0.3;
    span {
      color: #83c8ca;
    }
  }

  .active {
    opacity: 1;
    img {
      opacity: 1;
    }
    span {
      color: white;
    }
  }

  .nav-item {
    display: flex;
    align-items: center;
    width: 12rem;
    padding: 0.2em 1em 0.2em 2em;
    border-radius: 9px;
    margin: 0.1em auto;

    span {
      color: white;
      font-style: italic;
      font-weight: normal;
      padding: 0.5rem 0.5rem;
    }

    .nav-link {
      padding: 0.5rem 0.5rem;
      color: white !important;
    }

    &:hover,
    &.active {
      background: var(--color-turquoise);
      border: none;
    }
  }

  .charts {
    color: white;
    margin-left: 7px;

    span {
      margin-left: 1px;
    }
  }
}
